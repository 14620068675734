<template>
  <section class="l-content">
    <el-card>
      <el-form :inline="true" :model="condition">
        <el-form-item label="所属企业">
          <l-select v-model="condition.enterpriseCode" :remote="$api.enterpriseList" @change="getPageData"></l-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" placeholder="资产编号|资产名称"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getPageData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="enterpriseName" slot-scope="scope">
          <el-button type="text" @click="$site.view_enterprise_detail(scope.row.enterpriseCode)">{{ scope.row.enterpriseName }}</el-button>
        </template>
        <template slot="assetName" slot-scope="scope">
          <el-button type="text" @click="$site.view_asset_detail(scope.row.assetId)">{{ scope.row.assetName }}</el-button>
        </template>
        <template slot="borrowFlag" slot-scope="scope">
          <i v-if="scope.row.borrowFlag" class="el-icon-success text-green"></i>
          <i v-else class="el-icon-error text-red"></i>
        </template>
        <template slot="proposalStatus" slot-scope="scope">
          <span v-if="scope.row.proposalStatus == 0" class="text-grey">未提交</span>
          <span v-else-if="scope.row.proposalStatus == 1" class="text-blue">已提交</span>
          <span v-else-if="scope.row.proposalStatus == 2" class="text-mauve">已入围</span>
          <span v-else-if="scope.row.proposalStatus == 3" class="text-green">已采纳</span>
          <span v-else-if="scope.row.proposalStatus == -1" class="text-red">无效建议</span>
          <span v-else-if="scope.row.proposalStatus == -2" class="text-red">无法实施</span>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'ProposalList',
  data() {
    return {
      tableOption: {
        stripe: true,
        height: document.documentElement.clientHeight - 280 - 50 + 'px',
        columns: [
          { label: '序号', type: 'index' },
          { label: '所属企业', slot: 'enterpriseName' },
          { label: '资产名称', slot: 'assetName' },
          { label: '资产编号', prop: 'assetNo' },
          { label: '资产分类', prop: 'categoryFullName' },
          { label: '资产类型', prop: 'assetTypeName' },
          { label: '可借性', slot: 'borrowFlag' },
          { label: '责任人', prop: 'reponsibleUserName' },
          { label: '使用人', prop: 'useUserName' }
        ]
      },
      condition: {
        enterpriseCode: '',
        keyword: '',
        categoryCode: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/asset/list', this.condition)
    }
  }
}
</script>
